<template>
    <Layout>
        <div>
            <b-form-radio-group
                v-model="registrationType"
                :options="registrationOptions"
                class="mb-3"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
            ></b-form-radio-group>
        </div>
        <NewOwnerRegistration v-if="registrationType === 'OWNER'" />
        <TenantRegistration v-if="registrationType === 'TENANT'" />
        <StaffRegistration v-if="registrationType === 'STAFF'" />
    </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import NewOwnerRegistration from "../account/new-owner-registration.vue";
import TenantRegistration from "../account/tenant-registration.vue";
import StaffRegistration from "../account/staff-registration.vue";
export default {
    components: {
        Layout,
        NewOwnerRegistration,
        TenantRegistration,
        StaffRegistration,
    },
    data() {
        return {
            registrationType: null,
            registrationOptions: [
                { item: "STAFF", name: "Staff" },
                { item: "OWNER", name: "Owner" },
                { item: "TENANT", name: "Tenant" },
            ],
        };
    },
    mounted() {
        if (
            localStorage.cs_user_role === "Administrator" ||
            localStorage.cs_user_role === "Archiving" ||
            localStorage.cs_user_role === "Customer Service"
        ) {
            this.$store.dispatch("apidata/fetchCountries");
            this.$store.dispatch("apidata/fetchProjects");
            this.$store.dispatch("apidata/fetchGroups");
        } else {
            this.$router.push({
                path: "/",
            });
        }
    },
    methods: {},
};
</script>
<style scoped></style>
