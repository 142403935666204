<template>
    <div>
        <form class="form-horizontal" @submit.prevent="createTenant">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Project*</label>
                        <b-form-select
                            id="ownerfname"
                            v-model="tenant.project"
                            name="ownerfname"
                            @change="fetchUnits(tenant.project)"
                            :options="mapppedProjects"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.project.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.tenant.project.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.project.required"
                                >Please choose a project</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" v-if="!isUnitFetched">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            disabled
                            placeholder="No units available"
                            id="username"
                        />
                    </div>
                </div>
                <div class="col-lg-6" v-if="isUnitFetched">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <!-- <b-form-select
                            id="ownerfname"
                            v-model="tenant.unit_ids"
                            name="ownerfname"
                            @change="setUserName()"
                            :options="mappedUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.unit_ids.$error,
                            }"
                        ></b-form-select> -->
                        <model-select
                            :options="mappedUnits"
                            v-model="tenant.unit_ids"
                            placeholder="Search Units"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.unit_ids.$error,
                            }"
                        >
                        </model-select>
                        <!-- {{ tenant.unit_ids }} -->
                        <div
                            v-if="submitted && $v.tenant.unit_ids.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.unit_ids.required"
                                >Please choose a unit</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">First Name*</label>
                        <input
                            v-model="tenant.first_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.first_name.$error,
                            }"
                            @change="setPassword()"
                        />
                        <div
                            v-if="submitted && $v.tenant.first_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.first_name.required"
                                >First name required</span
                            >
                            <span v-if="!$v.tenant.first_name.minLength"
                                >Should have atlease 3 characters.</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Last Name*</label>
                        <input
                            v-model="tenant.last_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.last_name.$error,
                            }"
                            @change="setPassword()"
                        />
                        <div
                            v-if="submitted && $v.tenant.last_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.last_name.required"
                                >Last name required</span
                            >
                            <span v-if="!$v.tenant.last_name.minLength"
                                >Should have atlease 3 characters.</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">User Name</label>
                        <input
                            v-model="tenant.username"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            disabled
                            placeholder="Enter username"
                        />
                    </div>
                </div>
                <div
                    class="col-lg-6"
                    v-if="
                        tenant.username && tenant.first_name && tenant.last_name
                    "
                >
                    <div class="form-group inner-addon right-addon">
                        <label for="ownerfname">Password</label>

                        <div @click="viewPassword">
                            <i class="glyphicon dripicons-preview"></i>
                        </div>

                        <input
                            v-model="tenant.password"
                            v-if="showPassword"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            disabled
                        />
                        <input
                            v-model="tenant.password"
                            v-if="!showPassword"
                            type="password"
                            class="form-control mb-3"
                            id="username"
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Email*</label>
                        <input
                            v-model="tenant.email"
                            type="email"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.email.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.tenant.email.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.email.required"
                                >Email required</span
                            >
                            <span v-if="!$v.tenant.email.email"
                                >This value should be a valid email.</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Nationality*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="tenant.nationality"
                            :options="mappedCountries"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.nationality.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.tenant.nationality.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.nationality.required"
                                >Nationality required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Address*</label>

                        <b-form-textarea
                            id="textarea-small"
                            size="sm"
                            v-model="tenant.address"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.address.$error,
                            }"
                        ></b-form-textarea>
                        <div
                            v-if="submitted && $v.tenant.address.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.address.required"
                                >Address is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Mobile Number*</label>
                        <input
                            v-model="tenant.mobile_no"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.mobile_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.tenant.mobile_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.mobile_no.required"
                                >Mobile number is required</span
                            >

                            <span v-if="!$v.tenant.mobile_no.numeric"
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Passport Number*</label>
                        <input
                            v-model="tenant.passport_no"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.passport_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.tenant.passport_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.passport_no.required"
                                >Passport number is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Emirate Id Number*</label>
                        <input
                            v-model="tenant.eid_no"
                            type="text"
                            v-mask="'###-####-#######-#'"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.eid_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.tenant.eid_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.eid_no.required"
                                >Emirates Id number is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">PO Box*</label>
                        <input
                            v-model="tenant.po_box"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.tenant.po_box.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.tenant.po_box.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.tenant.po_box.required"
                                >PO Box required</span
                            >
                            <span v-if="!$v.tenant.po_box.numeric"
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <label for="ownerfname">Passport Copy*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleTenantPassport"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.tenant.passport.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.tenant.passport.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.tenant.passport.required"
                            >Passport file is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-4">
                    <label for="ownerfname">Visa Copy*</label>
                    <b-form-file
                        @change="handleTenantVisa"
                        placeholder="Choose File"
                        class="mb-3"
                        :class="{
                            'is-invalid': submitted && $v.tenant.visa.$error,
                        }"
                        drop-placeholder="Drop file here..."
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.tenant.visa.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.tenant.visa.required"
                            >Visa file is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-4">
                    <label for="ownerfname">Emirates Id Copy*</label>
                    <b-form-file
                        @change="handleTenantEmiratesId"
                        placeholder="Choose File"
                        class="mb-3"
                        drop-placeholder="Drop file here..."
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid': submitted && $v.tenant.eid.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.tenant.eid.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.tenant.eid.required"
                            >Emirates id file is required</span
                        >
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
import { required, email, numeric, minLength } from "vuelidate/lib/validators";
import { PASSWORD_BASE, BASE_URL } from "../../common";
import Swal from "sweetalert2";
import axios from "axios";
import submitButton from "../../../components/submitButton.vue";
import { ModelSelect } from "vue-search-select";
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { submitButton, ModelSelect },
    mixins: [unitMixin],
    validations: {
        tenant: {
            address: { required },
            eid: { required },
            eid_no: { required },
            email: { required, email },
            first_name: { required, minLength: minLength(3) },
            last_name: { required, minLength: minLength(3) },
            mobile_no: { required, numeric },
            nationality: { required },
            passport: { required },
            passport_no: { required },
            password: { required },
            po_box: { required, numeric },
            project: { required },
            unit_ids: { required },
            username: { required },
            visa: { required },
        },
    },
    computed: {
        countries() {
            return this.$store.getters["apidata/getCountries"];
        },
        projects() {
            return this.$store.getters["apidata/getProjects"];
        },
        mappedCountries() {
            return this.countries.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
        mapppedProjects() {
            return this.projects
                .filter((project) => {
                    return (
                        project.id == 4 ||
                        project.id == 5 ||
                        project.id == 6 ||
                        project.id == 7
                    );
                })
                .map((ele) => {
                    return {
                        value: ele.id,
                        text: ele.title,
                    };
                });
        },
        mappedUnits() {
            return this.units.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.ref_no,
                };
            });
        },
    },

    data() {
        return {
            submitted: false,
            showPassword: false,
            tenant: {
                address: "",
                eid: "",
                eid_filename: "",
                eid_no: "",
                email: "",
                first_name: "",
                last_name: "",
                mobile_no: "",
                name: "",
                nationality: "",
                passport: "",
                passport_filename: "",
                passport_no: "",
                password: "",
                po_box: "",
                project: "",
                unit_ids: "",
                user_type: "",
                username: "",
                visa: "",
                visa_filename: "",
            },
        };
    },
    watch: {
        "tenant.unit_ids"() {
            this.setUserName();
        },
    },
    methods: {
        setUserName() {
            const id = this.tenant.unit_ids;
            this.tenant.username =
                this.mappedUnits.find((item) => item.value === id).text + "T";
        },
        setPassword() {
            const id = this.tenant.unit_ids;
            let unit = this.mappedUnits.find((item) => item.value === id).text;
            this.tenant.password =
                PASSWORD_BASE +
                unit +
                this.tenant.first_name.slice(0, 2) +
                this.tenant.last_name.slice(0, 2);
        },
        createTenant() {
            this.submitted = true;
            this.$v.$touch();
            this.tenant.name =
                this.tenant.first_name + " " + this.tenant.last_name;
            this.tenant.user_type = "3";
            if (!this.$v.$error) {
                console.log(this.tenant);
                if (localStorage.cs_user_token) {
                    console.log(localStorage.cs_user_token);
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}auth/signup?token=${localStorage.cs_user_token}`,
                            this.tenant
                        )
                        .then((res) => {
                            loader.hide();
                            console.log(res);
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "New tenant created",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            this.resetFields();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                            Swal(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                }
            }
        },

        viewPassword() {
            this.showPassword = !this.showPassword;
        },

        handleTenantPassport(e) {
            let reader = new FileReader();
            this.tenant.passport_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.tenant.passport = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleTenantVisa(e) {
            let reader = new FileReader();
            this.tenant.visa_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.tenant.visa = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleTenantEmiratesId(e) {
            let reader = new FileReader();
            this.tenant.eid_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.tenant.eid = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
    },
};
</script>
<style scoped>
.inner-addon {
    position: relative;
}
.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: all;
}
.right-addon .glyphicon {
    right: 10px;
}
.glyphicon {
    size: 10px;
}
</style>
