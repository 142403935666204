<template>
    <div>
        <h2>create new staff</h2>
        <form class="form-horizontal" @submit.prevent="createStaff">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">User Name*</label>
                        <input
                            v-model="staff.username"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.username.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.username.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.email.required"
                                >User name is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Email*</label>
                        <input
                            v-model="staff.email"
                            type="email"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.email.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.email.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.email.required"
                                >Email required</span
                            >
                            <span v-if="!$v.staff.email.email"
                                >This value should be a valid email.</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group inner-addon right-addon">
                        <label for="ownerfname">Password*</label>

                        <div @click="viewPassword">
                            <i class="glyphicon dripicons-preview"></i>
                        </div>

                        <input
                            v-model="staff.password"
                            v-if="showPassword"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                        />
                        <input
                            v-model="staff.password"
                            v-if="!showPassword"
                            type="password"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.password.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.password.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.password.required"
                                >Password required</span
                            >
                            <span v-if="!$v.staff.password.minLength"
                                >Password must contain minimum 8
                                characters</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group inner-addon right-addon">
                        <label for="ownerfname">Confirm Password*</label>

                        <div @click="viewConfirmPassword">
                            <i class="glyphicon dripicons-preview"></i>
                        </div>

                        <input
                            v-model="staff.confirmPassword"
                            v-if="showConfirmPassword"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                        />
                        <input
                            v-model="staff.confirmPassword"
                            v-if="!showConfirmPassword"
                            type="password"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.staff.confirmPassword.$error,
                            }"
                        />

                        <div
                            v-if="submitted && $v.staff.confirmPassword.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.confirmPassword.required"
                                >Password required</span
                            >
                            &nbsp;
                            <span
                                v-if="!$v.staff.confirmPassword.sameAsPassword"
                                >Password must match</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">First Name*</label>
                        <input
                            v-model="staff.first_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.first_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.first_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.first_name.required"
                                >First name required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Last Name*</label>
                        <input
                            v-model="staff.last_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.last_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.last_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.last_name.required"
                                >Last name required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Nationality*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="staff.nationality"
                            :options="mappedCountries"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.nationality.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.staff.nationality.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.nationality.required"
                                >Nationality required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Address*</label>

                        <b-form-textarea
                            id="textarea-small"
                            size="sm"
                            v-model="staff.address"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.address.$error,
                            }"
                        ></b-form-textarea>
                        <div
                            v-if="submitted && $v.staff.address.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.address.required"
                                >Address is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Mobile Number*</label>
                        <input
                            v-model="staff.mobile_no"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.mobile_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.mobile_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.mobile_no.required"
                                >Mobile number is required</span
                            >

                            <span v-if="!$v.staff.mobile_no.numeric"
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">PO Box*</label>
                        <input
                            v-model="staff.po_box"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.po_box.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.po_box.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.po_box.required"
                                >PO Box required</span
                            >
                            <span v-if="!$v.staff.po_box.numeric"
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Employee Id*</label>
                        <input
                            v-model="staff.emp_id"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.emp_id.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.emp_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.emp_id.required"
                                >Employee Id is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Designation*</label>
                        <input
                            v-model="staff.designation"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.designation.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.staff.designation.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.designation.required"
                                >Designation is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Department*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="staff.department"
                            :options="departments"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.department.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.staff.department.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.department.required"
                                >Department is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">User Group*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="staff.usergroup"
                            :options="mappedGroups"
                            :class="{
                                'is-invalid':
                                    submitted && $v.staff.usergroup.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.staff.usergroup.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.staff.usergroup.required"
                                >User role is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="mt-3 mb-3">
                <button type="submit" class="btn btn-primary btn-lg">
                    Submit
                </button>
            </div> -->
            <submitButton />
        </form>
    </div>
</template>
<script>
import {
    required,
    email,
    numeric,
    minLength,
    sameAs,
} from "vuelidate/lib/validators";
import { BASE_URL } from "../../common";
import Swal from "sweetalert2";
import axios from "axios";
import submitButton from "../../../components/submitButton.vue";

export default {
    components: {
        submitButton,
    },
    validations: {
        staff: {
            address: { required },
            department: { required },
            designation: { required },
            email: { required, email },
            emp_id: { required },
            first_name: { required },
            last_name: { required },
            mobile_no: { required, numeric },
            nationality: { required },
            password: { required, minLength: minLength(8) },
            po_box: { required, numeric },
            usergroup: { required },
            username: { required },
            confirmPassword: { required, sameAsPassword: sameAs("password") },
        },
    },
    computed: {
        countries() {
            return this.$store.getters["apidata/getCountries"];
        },
        mappedCountries() {
            return this.countries.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
        userGroups() {
            return this.$store.getters["apidata/getGroups"];
        },
        mappedGroups() {
            return this.userGroups.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
    },
    data() {
        return {
            submitted: false,
            showPassword: false,
            showConfirmPassword: false,
            departments: [
                { value: null, text: "Choose a department" },
                { value: "ADMIN", text: " System admin" },
                { value: "CS", text: "Customer Service" },
                { value: "ENGG", text: "Engineering" },
                { value: "FM", text: "Facility Management" },
                { value: "MANG", text: "Managemnt" },
                //{ value:"SALES",text:"Sales"},
                { value: "AUDIT", text: "Audit" },
                { value: "ACCOUNTS", text: "Accounts" },
                { value: "IT", text: "Information Technology" },
                { value: "COLLECTION", text: "Collection Department" },
            ],
            staff: {
                address: "",
                department: "",
                designation: "",
                email: "",
                emp_id: "",
                first_name: "",
                last_name: "",
                mobile_no: "",
                name: "",
                nationality: "",
                password: "",
                po_box: "",
                user_type: "",
                usergroup: "",
                username: "",
                confirmPassword: "",
            },
        };
    },
    methods: {
        createStaff() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$error) {
                console.log("data");
            }
            if (!this.$v.$error) {
                this.staff.user_type = "1";
                this.staff.name =
                    this.staff.first_name + " " + this.staff.last_name;
                // eslint-disable-next-line no-unused-vars
                const { confirmPassword, ...staffRest } = this.staff;
                console.log(staffRest);
                if (localStorage.cs_user_token) {
                    console.log(localStorage.cs_user_token);
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}auth/signup?token=${localStorage.cs_user_token}`,
                            staffRest
                        )
                        .then((res) => {
                            loader.hide();
                            console.log(res);
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "New staff created",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            this.resetFields();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            console.log(err);
                            loader.hide();
                            Swal.fire(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                }
            }
        },
        viewPassword() {
            this.showPassword = !this.showPassword;
        },
        viewConfirmPassword() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
    },
};
</script>
<style scoped>
.inner-addon {
    position: relative;
}
.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    margin-right: 10px;
    pointer-events: all;
}
.right-addon .glyphicon {
    right: 10px;
}
.glyphicon {
    size: 10px;
}
</style>
