<template>
    <div>
        <form class="form-horizontal" @submit.prevent="createOwner">
            <div class="row">
                <div class="col-lg-4">
                    <label for="ownerfname">Number of owners*</label>
                    <b-form-select
                        v-model="selected"
                        @change="addOwnerForm()"
                        :options="options"
                        :class="{
                            'is-invalid': submitted && $v.selected.$error,
                        }"
                    ></b-form-select>
                    <div
                        v-if="submitted && $v.selected.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.selected.required"
                            >Please choose the number of owners</span
                        >
                    </div>
                </div>
                <div class="col-lg-4" v-if="selected">
                    <div class="form-group">
                        <label for="ownerfname">Project*</label>
                        <b-form-select
                            id="ownerfname"
                            v-model="owner.project"
                            name="ownerfname"
                            @change="fetchUnits(owner.project)"
                            :options="mapppedProjects"
                            :class="{
                                'is-invalid':
                                    submitted && $v.owner.project.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.owner.project.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.owner.project.required"
                                >Please choose a project</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" v-if="!isUnitFetched && owner.project">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            disabled
                            placeholder="No units available"
                            id="username"
                        />
                    </div>
                </div>
                <div class="col-lg-4" v-if="isUnitFetched && owner.project">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <!-- <b-form-select
                            id="ownerfname"
                            multiple
                            v-model="owner.unit_ids"
                            name="ownerfname"
                            @change="setUserName()"
                            :options="mappedUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.owner.unit_ids.$error,
                            }"
                        ></b-form-select> -->
                        <multi-select
                            id="ownerfname"
                            name="ownerfname"
                            :selected-options="unitIds"
                            placeholder="Search Units"
                            @select="onSelect"
                            :options="mappedUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.owner.unit_ids.$error,
                            }"
                        >
                        </multi-select>
                        <!-- {{ unitIds }}
                       
                        {{ owner.unit_ids }} -->
                        <div
                            v-if="submitted && $v.owner.unit_ids.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.owner.unit_ids.required"
                                >Please choose a unit</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="owner.unit_ids.length">
                <h3>Owner 1</h3>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">First Name*</label>
                            <input
                                v-model="owner.first_name"
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                @change="setPassword()"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.owner.first_name.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.owner.first_name.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.first_name.required"
                                    >First name required</span
                                >
                                <span v-if="!$v.owner.first_name.minLength"
                                    >Should have atlease 3 characters.</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Last Name*</label>
                            <input
                                v-model="owner.last_name"
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.owner.last_name.$error,
                                }"
                                @change="setPassword()"
                            />
                            <div
                                v-if="submitted && $v.owner.last_name.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.last_name.required"
                                    >Last name required</span
                                >
                                <span v-if="!$v.owner.last_name.minLength"
                                    >Should have atlease 3 characters.</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">User Name</label>
                            <input
                                v-model="owner.username"
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                disabled
                                placeholder="Enter username"
                            />
                        </div>
                    </div>
                    <div
                        class="col-lg-6"
                        v-if="owner.first_name && owner.last_name"
                    >
                        <label for="ownerfname">Password</label>

                        <div class="form-group inner-addon right-addon">
                            <h4>
                                <div @click="viewPassword">
                                    <i class="glyphicon dripicons-preview"></i>
                                </div>
                            </h4>
                            <input
                                v-model="owner.password"
                                v-if="showPassword"
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                disabled
                            />
                            <input
                                v-model="owner.password"
                                v-if="!showPassword"
                                type="password"
                                class="form-control mb-3"
                                id="username"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Email*</label>
                            <input
                                v-model="owner.email"
                                type="email"
                                class="form-control mb-3"
                                id="username"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.owner.email.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.owner.email.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.email.required"
                                    >Email required</span
                                >
                                <span v-if="!$v.owner.email.email"
                                    >This value should be a valid email.</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Nationality</label>
                            <b-form-select
                                id="ownerfname"
                                name="ownerfname"
                                v-model="owner.nationality"
                                :options="mappedCountries"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.owner.nationality.$error,
                                }"
                            ></b-form-select>
                            <div
                                v-if="submitted && $v.owner.nationality.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.nationality.required"
                                    >Nationality required</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Address*</label>

                            <b-form-textarea
                                id="textarea-small"
                                size="sm"
                                v-model="owner.address"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.owner.address.$error,
                                }"
                            ></b-form-textarea>
                            <div
                                v-if="submitted && $v.owner.address.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.address.required"
                                    >Address is required</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">PO Box*</label>
                            <input
                                v-model="owner.po_box"
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.owner.po_box.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.owner.po_box.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.po_box.required"
                                    >PO Box required</span
                                >
                                <span v-if="!$v.owner.po_box.numeric"
                                    >Only numeric values allowed</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Mobile Number*</label>
                            <input
                                v-model="owner.mobile_no"
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.owner.mobile_no.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.owner.mobile_no.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.mobile_no.required"
                                    >Mobile number required</span
                                >
                                <span v-if="!$v.owner.mobile_no.numeric"
                                    >Only numeric values allowed</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Emirate Id Number*</label>
                            <input
                                v-model="owner.eid_no"
                                type="text"
                                v-mask="'###-####-#######-#'"
                                class="form-control mb-3"
                                id="username"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.owner.eid_no.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.owner.eid_no.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.eid_no.required"
                                    >Emirates Id number is required</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Passport Number*</label>
                            <input
                                v-model="owner.passport_no"
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.owner.passport_no.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.owner.passport_no.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.owner.passport_no.required"
                                    >Passport number is required</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label for="ownerfname">Passport Copy</label>
                        <b-form-file
                            placeholder="Choose File"
                            drop-placeholder="Drop file here..."
                            @change="handleOwnerPassport"
                            class="mb-3"
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                            :class="{
                                'is-invalid':
                                    submitted && $v.owner.passport.$error,
                            }"
                        ></b-form-file>
                        <div
                            v-if="submitted && $v.owner.passport.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.owner.passport.required"
                                >Passport file is required</span
                            >
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="ownerfname">Visa Copy*</label>
                        <b-form-file
                            @change="handleOwnerVisa"
                            placeholder="Choose File"
                            class="mb-3"
                            :class="{
                                'is-invalid': submitted && $v.owner.visa.$error,
                            }"
                            drop-placeholder="Drop file here..."
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                        ></b-form-file>
                        <div
                            v-if="submitted && $v.owner.visa.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.owner.visa.required"
                                >Visa file is required</span
                            >
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="ownerfname">Emirates Id Copy*</label>
                        <b-form-file
                            @change="handleOwnerEmiratesId"
                            placeholder="Choose File"
                            class="mb-3"
                            drop-placeholder="Drop file here..."
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                            :class="{
                                'is-invalid': submitted && $v.owner.eid.$error,
                            }"
                        ></b-form-file>
                        <div
                            v-if="submitted && $v.owner.eid.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.owner.eid.required"
                                >Emirates id file is required</span
                            >
                        </div>
                    </div>
                </div>

                <div
                    v-for="(buyer, index) in owner.buyers"
                    :key="index"
                    :set="(v = $v.owner.buyers.$each[index])"
                >
                    <div v-if="selected" class="mt-3">
                        <h3>Owner {{ index + 2 }}</h3>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"> First Name*</label>
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_first_name"
                                        name="ownerfname"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_first_name.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            v.buyer_first_name.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="!v.buyer_first_name.required"
                                            >First name is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"> Last Name*</label>
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_last_name"
                                        name="ownerfname"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_last_name.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            v.buyer_last_name.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_last_name.required"
                                            >Last name required</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"> PO Box* </label>
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_po_box"
                                        name="ownerfname"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_po_box.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted && v.buyer_po_box.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_po_box.required"
                                            >PO Box is required</span
                                        >
                                        <span v-if="!v.buyer_po_box.numeric"
                                            >Only numeric values allowed</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname">
                                        Phone Number*</label
                                    >
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_phone_no"
                                        name="ownerfname"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_phone_no.$error,
                                        }"
                                        type="tel"
                                        class="form-control"
                                    />
                                    <div
                                        v-if="
                                            submitted && v.buyer_phone_no.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_phone_no.required"
                                            >Phone number is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"> Fax Number</label>
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_fax_no"
                                        name="ownerfname"
                                        type="text"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname">
                                        Mobile Number*</label
                                    >
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_mobile_no"
                                        name="ownerfname"
                                        type="tel"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_mobile_no.$error,
                                        }"
                                        class="form-control"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            v.buyer_mobile_no.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_mobile_no.required"
                                            >Mobile number is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"> Email*</label>
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_email"
                                        name="ownerfname"
                                        type="email"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_email.$error,
                                        }"
                                    />
                                    <div
                                        v-if="submitted && v.buyer_email.$error"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_email.required"
                                            >Email is required</span
                                        >
                                        <span v-if="!v.buyer_email.email"
                                            >Enter a valid email</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname">
                                        Passport Number*</label
                                    >
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_passport_no"
                                        name="ownerfname"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_passport_no.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            v.buyer_passport_no.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="!v.buyer_passport_no.required"
                                            >Passport number is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"> Country*</label>
                                    <b-form-select
                                        id="ownerfname"
                                        name="ownerfname"
                                        v-model="buyer.buyer_country"
                                        :options="mappedCountries"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_country.$error,
                                        }"
                                    ></b-form-select>
                                    <div
                                        v-if="
                                            submitted && v.buyer_country.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_country.required"
                                            >Country is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname">
                                        Emirates Id Number*</label
                                    >
                                    <input
                                        id="ownerfname"
                                        v-model="buyer.buyer_eid_no"
                                        name="ownerfname"
                                        v-mask="'###-####-#######-#'"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_eid_no.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted && v.buyer_eid_no.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_eid_no.required"
                                            >Emirate id number is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-row>
                            <b-col sm="2">
                                <label for="textarea-small"
                                    >Physical Address*</label
                                >
                            </b-col>
                            <b-col sm="12">
                                <b-form-textarea
                                    id="textarea-small"
                                    size="sm"
                                    v-model="buyer.buyer_physical_address"
                                    placeholder="Address"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v.buyer_physical_address.$error,
                                    }"
                                ></b-form-textarea>
                                <div
                                    v-if="
                                        submitted &&
                                        v.buyer_physical_address.$error
                                    "
                                    class="invalid-feedback"
                                >
                                    <span
                                        v-if="
                                            !v.buyer_physical_address.required
                                        "
                                        >Address is required</span
                                    >
                                </div>
                            </b-col>
                        </b-row>

                        <div class="row mt-3 mb-5">
                            <div class="col-lg-4">
                                <label for="ownerfname">Passport Copy*</label>
                                <b-form-file
                                    @change="handleBuyerPassport($event, index)"
                                    placeholder="Choose File"
                                    v-model="buyer.buyer_passport"
                                    drop-placeholder="Drop file here..."
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v.buyer_passport.$error,
                                    }"
                                ></b-form-file>
                                <div
                                    v-if="submitted && v.buyer_passport.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!v.buyer_passport.required"
                                        >Passport file is required</span
                                    >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="ownerfname">Visa Copy*</label>
                                <b-form-file
                                    @change="handleBuyerVisa($event, index)"
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                    :class="{
                                        'is-invalid':
                                            submitted && v.buyer_visa.$error,
                                    }"
                                ></b-form-file>
                                <div
                                    v-if="submitted && v.buyer_visa.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!v.buyer_visa.required"
                                        >Visa file is required</span
                                    >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="ownerfname"
                                    >Emirates Id Copy*</label
                                >
                                <b-form-file
                                    @change="
                                        handleBuyerEmiratesId($event, index)
                                    "
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                    :class="{
                                        'is-invalid':
                                            submitted && v.buyer_eid.$error,
                                    }"
                                ></b-form-file>
                                <div
                                    v-if="submitted && v.buyer_eid.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!v.buyer_eid.required"
                                        >Emirates Id file is required</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
import { PASSWORD_BASE, BASE_URL } from "../../common";
import axios from "axios";
import { required, email, numeric, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import submitButton from "../../../components/submitButton.vue";

import { MultiSelect } from "vue-search-select";
export default {
    mixins: [unitMixin],
    props: {},
    mounted() {},

    components: { submitButton, MultiSelect },
    validations: {
        owner: {
            address: { required },
            unit_ids: { required },
            eid: { required },
            eid_no: { required },
            email: { required, email },
            first_name: { required, minLength: minLength(3) },
            last_name: { required, minLength: minLength(3) },
            mobile_no: { required, numeric },
            nationality: { required },
            passport: { required },
            passport_no: { required },
            password: { required },
            po_box: { required, numeric },
            project: { required },
            username: { required },
            visa: { required },
            buyers: {
                $each: {
                    buyer_first_name: { required },
                    buyer_country: { required },
                    buyer_eid: { required },
                    buyer_eid_no: { required },
                    buyer_email: { required, email },
                    buyer_last_name: { required },
                    buyer_mobile_no: { required, numeric },
                    buyer_passport: { required },
                    buyer_passport_no: { required },
                    buyer_phone_no: { required },
                    buyer_physical_address: { required },
                    buyer_po_box: { required, numeric },
                    buyer_visa: { required },
                },
            },
        },
        selected: { required },
    },
    computed: {
        countries() {
            return this.$store.getters["apidata/getCountries"];
        },
        projects() {
            return this.$store.getters["apidata/getProjects"];
        },
        mappedCountries() {
            return this.countries.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
        mapppedProjects() {
            return this.projects
                .filter((project) => {
                    return (
                        project.id == 4 ||
                        project.id == 7 ||
                        project.id == 6 ||
                        project.id == 5
                    );
                })
                .map((ele) => {
                    return {
                        value: ele.id,
                        text: ele.title,
                    };
                });
        },
        mappedUnits() {
            return this.units.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.ref_no,
                };
            });
        },
    },

    data() {
        return {
            unitIds: [],
            searchText: "", // If value is falsy, reset searchText & searchItem
            items: [],
            lastSelectItem: {},
            ownerType: null,
            ownerOptions: [
                { item: "NEW", name: "New Owner" },
                { item: "EXISTING", name: "Existing Owner" },
            ],
            submitted: false,
            selected: null,
            ownerSelected: false,
            updatedCountries: [],
            type: "password",
            showPassword: false,
            options: [
                { value: null, text: "Number of owners" },
                { value: "1", text: "1" },
                { value: "2", text: "2" },
                { value: "3", text: "3" },
                { value: "4", text: "4" },
                { value: "5", text: "5" },
                { value: "6", text: "6" },
            ],
            owners: [{ name: "" }],
            confirmPassword: "",
            owner: {
                address: "",
                eid: "",
                eid_filename: "",
                eid_no: "",
                email: "",
                first_name: "",
                last_name: "",
                mobile_no: "",
                name: "",
                nationality: "",
                owner_type: "",
                passport: "",
                passport_filename: "",
                passport_no: "",
                password: "",
                po_box: "",
                project: "",
                user_type: "",
                username: "",
                visa: "",
                visa_filename: "",
                buyers: [
                    {
                        buyer_city: "",
                        buyer_country: "",
                        buyer_eid: "",
                        buyer_eid_filename: "",
                        buyer_eid_no: "",
                        buyer_email: "",
                        buyer_fax_no: "",
                        buyer_first_name: "",
                        buyer_last_name: "",
                        buyer_mobile_no: "",
                        buyer_passport: "",
                        buyer_passport_filename: "",
                        buyer_passport_no: "",
                        buyer_phone_no: "",
                        buyer_physical_address: "",
                        buyer_po_box: "",
                        buyer_visa: "",
                        buyer_visa_filename: "",
                    },
                ],
                unit_ids: [],
            },
        };
    },
    methods: {
        onSelect(items, lastSelectItem) {
            // console.log("select statement working", items[0].value);
            // this.owner.unit_ids = items;
            this.unitIds = items;
            this.owner.unit_ids = this.unitIds.map((item) => {
                return item.value;
            });
            this.lastSelectItem = lastSelectItem;
            this.setUserName();
        },
        // deselect option
        reset() {
            this.owner.unit_ids = [];
            this.unitIds = [];
        },

        test() {
            console.log(this.ownerType);
        },
        setUserName() {
            console.log("Trying to set user name");
            const id = this.owner.unit_ids[0];
            console.log("the id is", id.value);
            this.owner.username = this.mappedUnits.find(
                (item) => item.value === id
            ).text;
            if (!this.owner.unit_ids.length) {
                this.owner.username = "";
            }
            console.log("the user name is ", this.owner.username);
        },
        setPassword() {
            this.owner.password =
                PASSWORD_BASE +
                this.owner.username +
                this.owner.first_name.slice(0, 2) +
                this.owner.last_name.slice(0, 2);
        },
        addOwnerForm() {
            this.ownerSelected = true;
            console.log(this.selected);
            if (this.selected === "1") {
                this.owner.buyers = [];
                console.log(this.selected);
                return;
            }
            this.owner.buyers = [];
            for (let index = 0; index < this.selected - 1; index++) {
                this.owner.buyers.push({
                    buyer_city: "",
                    buyer_country: "",
                    buyer_eid: "",
                    buyer_eid_filename: "",
                    buyer_eid_no: "",
                    buyer_email: "",
                    buyer_fax_no: "",
                    buyer_first_name: "",
                    buyer_last_name: "",
                    buyer_mobile_no: "",
                    buyer_passport: "",
                    buyer_passport_filename: "",
                    buyer_passport_no: "",
                    buyer_phone_no: "",
                    buyer_physical_address: "",
                    buyer_po_box: "",
                    buyer_visa: "",
                    buyer_visa_filename: "",
                });
            }
        },

        handleOwnerPassport(e) {
            let reader = new FileReader();
            this.owner.passport_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.owner.passport = e.target.result.split(",")[1];
                console.log(this.owner.passport);

                console.log(this.owner.passport_filename);
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleOwnerVisa(e) {
            let reader = new FileReader();
            this.owner.visa_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.owner.visa = e.target.result.split(",")[1];
                console.log(this.owner.visa);
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleOwnerEmiratesId(e) {
            let reader = new FileReader();
            this.owner.eid_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.owner.eid = e.target.result.split(",")[1];
                console.log(this.owner.eid);
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleBuyerPassport(e, index) {
            let reader = new FileReader();
            this.owner.buyers[index].buyer_passport_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.owner.buyers[index].buyer_passport =
                    e.target.result.split(",")[1];
                console.log(this.owner);
            };
            reader.readAsDataURL(e.target.files[0]);
            console.log(this.owner.buyers);
        },

        handleBuyerVisa(e, index) {
            let reader = new FileReader();
            this.owner.buyers[index].buyer_visa_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.owner.buyers[index].buyer_visa =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
            console.log(this.owner.buyers);
        },

        handleBuyerEmiratesId(e, index) {
            let reader = new FileReader();
            this.owner.buyers[index].buyer_eid_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.owner.buyers[index].buyer_eid =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
            console.log(this.owner.buyers);
        },

        viewPassword() {
            this.showPassword = !this.showPassword;
        },

        createOwner() {
            this.submitted = true;
            this.$v.$touch();
            this.owner.name =
                this.owner.first_name + " " + this.owner.last_name;
            this.owner.owner_type = "1";
            this.owner.user_type = "2";
            if (!this.$v.$error) {
                console.log(this.owner);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    console.log(localStorage.cs_user_token);
                    axios
                        .post(
                            `${BASE_URL}auth/signup?token=${localStorage.cs_user_token}`,
                            this.owner
                        )
                        .then((res) => {
                            console.log(res);
                            loader.hide();
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "New owner created",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            this.resetFields();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                            Swal(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                }
            }
        },
        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
    },
};
</script>
<style scoped>
.inner-addon {
    position: relative;
}
.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: all;
}
.right-addon .glyphicon {
    right: 10px;
}
</style>
