var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("create new staff")]),_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.createStaff.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("User Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.username),expression:"staff.username"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.username.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "username", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.username.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.email.required)?_c('span',[_vm._v("User name is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Email*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.email),expression:"staff.email"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.email.$error,
                        },attrs:{"type":"email","id":"username"},domProps:{"value":(_vm.staff.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.email.required)?_c('span',[_vm._v("Email required")]):_vm._e(),(!_vm.$v.staff.email.email)?_c('span',[_vm._v("This value should be a valid email.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group inner-addon right-addon"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Password*")]),_c('div',{on:{"click":_vm.viewPassword}},[_c('i',{staticClass:"glyphicon dripicons-preview"})]),(_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.password),expression:"staff.password"}],staticClass:"form-control mb-3",attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "password", $event.target.value)}}}):_vm._e(),(!_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.password),expression:"staff.password"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.password.$error,
                        },attrs:{"type":"password","id":"username"},domProps:{"value":(_vm.staff.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "password", $event.target.value)}}}):_vm._e(),(_vm.submitted && _vm.$v.staff.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.password.required)?_c('span',[_vm._v("Password required")]):_vm._e(),(!_vm.$v.staff.password.minLength)?_c('span',[_vm._v("Password must contain minimum 8 characters")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group inner-addon right-addon"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Confirm Password*")]),_c('div',{on:{"click":_vm.viewConfirmPassword}},[_c('i',{staticClass:"glyphicon dripicons-preview"})]),(_vm.showConfirmPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.confirmPassword),expression:"staff.confirmPassword"}],staticClass:"form-control mb-3",attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "confirmPassword", $event.target.value)}}}):_vm._e(),(!_vm.showConfirmPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.confirmPassword),expression:"staff.confirmPassword"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.staff.confirmPassword.$error,
                        },attrs:{"type":"password","id":"username"},domProps:{"value":(_vm.staff.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "confirmPassword", $event.target.value)}}}):_vm._e(),(_vm.submitted && _vm.$v.staff.confirmPassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.confirmPassword.required)?_c('span',[_vm._v("Password required")]):_vm._e(),_vm._v("   "),(!_vm.$v.staff.confirmPassword.sameAsPassword)?_c('span',[_vm._v("Password must match")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("First Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.first_name),expression:"staff.first_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.first_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "first_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.first_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.first_name.required)?_c('span',[_vm._v("First name required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Last Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.last_name),expression:"staff.last_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.last_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "last_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.last_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.last_name.required)?_c('span',[_vm._v("Last name required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Nationality*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.nationality.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedCountries},model:{value:(_vm.staff.nationality),callback:function ($$v) {_vm.$set(_vm.staff, "nationality", $$v)},expression:"staff.nationality"}}),(_vm.submitted && _vm.$v.staff.nationality.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.nationality.required)?_c('span',[_vm._v("Nationality required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Address*")]),_c('b-form-textarea',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.address.$error,
                        },attrs:{"id":"textarea-small","size":"sm"},model:{value:(_vm.staff.address),callback:function ($$v) {_vm.$set(_vm.staff, "address", $$v)},expression:"staff.address"}}),(_vm.submitted && _vm.$v.staff.address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.address.required)?_c('span',[_vm._v("Address is required")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Mobile Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.mobile_no),expression:"staff.mobile_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.mobile_no.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.mobile_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "mobile_no", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.mobile_no.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.mobile_no.required)?_c('span',[_vm._v("Mobile number is required")]):_vm._e(),(!_vm.$v.staff.mobile_no.numeric)?_c('span',[_vm._v("Only numeric values allowed")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("PO Box*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.po_box),expression:"staff.po_box"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.po_box.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.po_box)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "po_box", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.po_box.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.po_box.required)?_c('span',[_vm._v("PO Box required")]):_vm._e(),(!_vm.$v.staff.po_box.numeric)?_c('span',[_vm._v("Only numeric values allowed")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Employee Id*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.emp_id),expression:"staff.emp_id"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.emp_id.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.emp_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "emp_id", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.emp_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.emp_id.required)?_c('span',[_vm._v("Employee Id is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Designation*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.designation),expression:"staff.designation"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.designation.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.staff.designation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "designation", $event.target.value)}}}),(_vm.submitted && _vm.$v.staff.designation.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.designation.required)?_c('span',[_vm._v("Designation is required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Department*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.department.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.departments},model:{value:(_vm.staff.department),callback:function ($$v) {_vm.$set(_vm.staff, "department", $$v)},expression:"staff.department"}}),(_vm.submitted && _vm.$v.staff.department.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.department.required)?_c('span',[_vm._v("Department is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("User Group*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.staff.usergroup.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedGroups},model:{value:(_vm.staff.usergroup),callback:function ($$v) {_vm.$set(_vm.staff, "usergroup", $$v)},expression:"staff.usergroup"}}),(_vm.submitted && _vm.$v.staff.usergroup.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.staff.usergroup.required)?_c('span',[_vm._v("User role is required")]):_vm._e()]):_vm._e()],1)])]),_c('submitButton')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }