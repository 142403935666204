var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.createTenant.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Project*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.project.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mapppedProjects},on:{"change":function($event){return _vm.fetchUnits(_vm.tenant.project)}},model:{value:(_vm.tenant.project),callback:function ($$v) {_vm.$set(_vm.tenant, "project", $$v)},expression:"tenant.project"}}),(_vm.submitted && _vm.$v.tenant.project.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.project.required)?_c('span',[_vm._v("Please choose a project")]):_vm._e()]):_vm._e()],1)]),(!_vm.isUnitFetched)?_c('div',{staticClass:"col-lg-6"},[_vm._m(0)]):_vm._e(),(_vm.isUnitFetched)?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('model-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.unit_ids.$error,
                        },attrs:{"options":_vm.mappedUnits,"placeholder":"Search Units"},model:{value:(_vm.tenant.unit_ids),callback:function ($$v) {_vm.$set(_vm.tenant, "unit_ids", $$v)},expression:"tenant.unit_ids"}}),(_vm.submitted && _vm.$v.tenant.unit_ids.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.unit_ids.required)?_c('span',[_vm._v("Please choose a unit")]):_vm._e()]):_vm._e()],1)]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("First Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.first_name),expression:"tenant.first_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.first_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.tenant.first_name)},on:{"change":function($event){return _vm.setPassword()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "first_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.tenant.first_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.first_name.required)?_c('span',[_vm._v("First name required")]):_vm._e(),(!_vm.$v.tenant.first_name.minLength)?_c('span',[_vm._v("Should have atlease 3 characters.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Last Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.last_name),expression:"tenant.last_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.last_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.tenant.last_name)},on:{"change":function($event){return _vm.setPassword()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "last_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.tenant.last_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.last_name.required)?_c('span',[_vm._v("Last name required")]):_vm._e(),(!_vm.$v.tenant.last_name.minLength)?_c('span',[_vm._v("Should have atlease 3 characters.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("User Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.username),expression:"tenant.username"}],staticClass:"form-control mb-3",attrs:{"type":"text","id":"username","disabled":"","placeholder":"Enter username"},domProps:{"value":(_vm.tenant.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "username", $event.target.value)}}})])]),(
                    _vm.tenant.username && _vm.tenant.first_name && _vm.tenant.last_name
                )?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group inner-addon right-addon"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Password")]),_c('div',{on:{"click":_vm.viewPassword}},[_c('i',{staticClass:"glyphicon dripicons-preview"})]),(_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.password),expression:"tenant.password"}],staticClass:"form-control mb-3",attrs:{"type":"text","id":"username","disabled":""},domProps:{"value":(_vm.tenant.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "password", $event.target.value)}}}):_vm._e(),(!_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.password),expression:"tenant.password"}],staticClass:"form-control mb-3",attrs:{"type":"password","id":"username","disabled":""},domProps:{"value":(_vm.tenant.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "password", $event.target.value)}}}):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Email*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.email),expression:"tenant.email"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.email.$error,
                        },attrs:{"type":"email","id":"username"},domProps:{"value":(_vm.tenant.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.tenant.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.email.required)?_c('span',[_vm._v("Email required")]):_vm._e(),(!_vm.$v.tenant.email.email)?_c('span',[_vm._v("This value should be a valid email.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Nationality*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.nationality.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedCountries},model:{value:(_vm.tenant.nationality),callback:function ($$v) {_vm.$set(_vm.tenant, "nationality", $$v)},expression:"tenant.nationality"}}),(_vm.submitted && _vm.$v.tenant.nationality.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.nationality.required)?_c('span',[_vm._v("Nationality required")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Address*")]),_c('b-form-textarea',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.address.$error,
                        },attrs:{"id":"textarea-small","size":"sm"},model:{value:(_vm.tenant.address),callback:function ($$v) {_vm.$set(_vm.tenant, "address", $$v)},expression:"tenant.address"}}),(_vm.submitted && _vm.$v.tenant.address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.address.required)?_c('span',[_vm._v("Address is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Mobile Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.mobile_no),expression:"tenant.mobile_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.mobile_no.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.tenant.mobile_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "mobile_no", $event.target.value)}}}),(_vm.submitted && _vm.$v.tenant.mobile_no.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.mobile_no.required)?_c('span',[_vm._v("Mobile number is required")]):_vm._e(),(!_vm.$v.tenant.mobile_no.numeric)?_c('span',[_vm._v("Only numeric values allowed")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Passport Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.passport_no),expression:"tenant.passport_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.passport_no.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.tenant.passport_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "passport_no", $event.target.value)}}}),(_vm.submitted && _vm.$v.tenant.passport_no.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.passport_no.required)?_c('span',[_vm._v("Passport number is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Emirate Id Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.eid_no),expression:"tenant.eid_no"},{name:"mask",rawName:"v-mask",value:('###-####-#######-#'),expression:"'###-####-#######-#'"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.eid_no.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.tenant.eid_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "eid_no", $event.target.value)}}}),(_vm.submitted && _vm.$v.tenant.eid_no.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.eid_no.required)?_c('span',[_vm._v("Emirates Id number is required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("PO Box*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.po_box),expression:"tenant.po_box"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.tenant.po_box.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.tenant.po_box)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tenant, "po_box", $event.target.value)}}}),(_vm.submitted && _vm.$v.tenant.po_box.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.po_box.required)?_c('span',[_vm._v("PO Box required")]):_vm._e(),(!_vm.$v.tenant.po_box.numeric)?_c('span',[_vm._v("Only numeric values allowed")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Passport Copy*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.tenant.passport.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleTenantPassport}}),(_vm.submitted && _vm.$v.tenant.passport.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.passport.required)?_c('span',[_vm._v("Passport file is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Visa Copy*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid': _vm.submitted && _vm.$v.tenant.visa.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleTenantVisa}}),(_vm.submitted && _vm.$v.tenant.visa.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.visa.required)?_c('span',[_vm._v("Visa file is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Emirates Id Copy*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid': _vm.submitted && _vm.$v.tenant.eid.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleTenantEmiratesId}}),(_vm.submitted && _vm.$v.tenant.eid.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.tenant.eid.required)?_c('span',[_vm._v("Emirates id file is required")]):_vm._e()]):_vm._e()],1)]),_c('submitButton')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('input',{staticClass:"form-control mb-3",attrs:{"type":"text","disabled":"","placeholder":"No units available","id":"username"}})])}]

export { render, staticRenderFns }